import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
// Common Field
import Input from '../../components/commonfield/Input';
import PasswordInput from '../../components/commonfield/PasswordInput';
import CheckInput from '../../components/commonfield/CheckInput';
import FlashMessage from '../../components/FlashMessage'; 

// Images Common File
import HeaderSignInUp from './HeaderSignInUp';
import Logo from '../../components/Logo';
import * as Img from '../../components/Img';

export default function PageSignIn() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { dispatch, state } = useAuth();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      navigate('/home')
    }
  }, [navigate,state.userData]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('rememberUser'));
    if (storedUser && storedUser.email) {
      setEmail(storedUser.email);
      setPassword(storedUser.password);
      setRemember(true);
    }
  }, []);

  const handleEmailChange = (value) => {
    setEmail(value);
    setErrors((prev) => ({ ...prev, email: '' }));
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setErrors((prev) => ({ ...prev, password: '' })); 
  }; 

  const handleRememberChange = (event) => {
    var remember = event.target.checked;
    setRemember(remember)
  }

  const validateFieldsStep = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    } 

    if(!password){
      newErrors.password = 'Password is required';
    }
   
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFieldsStep();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('email',email);
      formData.append('password',password);
      
      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'login', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == 0){
              
              dispatch({ type: 'LOGIN', payload: { user: response.data.data, token: response.data.data.token,remember:remember, password: password}});
              // if (remember) {
              //   localStorage.setItem('authToken', response.data.data.token);
              // }    
              setEmail('');
              setPassword('');
              // setRemember(true);
              setRequestSubmitSuccsess(true)

              setTimeout(() => {
                navigate('/home');
              }, 2000);
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };

  // useEffect(() => {
  //   const token = localStorage.getItem('authToken');
  //   if (token) {
  //     // Dispatch an action to set the user in the state based on the token
  //     // Example:
  //     // dispatch({ type: 'LOGIN_WITH_TOKEN', payload: { token } });
  //   }
  // }, []);

  return (
    <>
    <section className="signinup-main-box">
        <HeaderSignInUp />

        <div className="signinup-screen">

            <Logo className="signinup-logo-bx" />

            <div className="signinup-header">
              <div className="screen-hed">Sign in to your account</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                   <Input heading="Your email / phone" type="email" placeholder="Enter Email or Phone Number" errorMeg={errors?.email} error={errors?.email} value={email} onChange={handleEmailChange}/>
                   {/* <Input heading="Your email" type="email" placeholder="Enter Email" errorMeg={errors?.email} error={errors?.email} value={email} onChange={handleEmailChange}/> */}
                </div>

                <div className="col-12">
                   <PasswordInput heading="Password" type="password" placeholder="Enter password" errorMeg={errors?.password} error={errors?.password} value={password} onChange={handlePasswordChange}/>
                </div>

                <div className="col-12">
                  <div className="signinup-link-outer-bx">
                    <CheckInput className="sgc-ci-18px mb-0" name="RememberCheck" id="RememberCheck" label="Remember me" onChange={handleRememberChange} checked={remember ? true : false} value={remember}/>
                    <Link to="/forgot-password" className="signinup-link-two mt-0" >Forgot Password?</Link>
                  </div>
                </div>

                <div className="col-12">
                  {!isLoading && <div className="common-submit-btn" onClick={handleSubmit}>Sign in</div>
                  }
                  {isLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                  </div>
                  }
                  {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                </div>

                <div className="col-12">
                  <div className="signinup-link-outer-bx slob-fdc mb-0">If you don’t have an account you can<Link className="signinup-link-two" to="/register">Register Here!</Link></div>
                </div>

              </div>
            </form>

        </div>
    </section>
     <FlashMessage type="success" isVisible={requestSubmitSuccess} message="You have successfully logged in" />
     <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
