import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeModeContext = createContext();

export const ThemeModeProvider = ({ children }) => {

  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check local storage for the theme preference
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : true;
  });

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('darkmode');
      localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('darkmode');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeModeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeModeContext.Provider>
  );
};

export const ThemeMode = () => {
  const context = useContext(ThemeModeContext);
  if (!context) {
    throw new Error('ThemeMode must be used within a ThemeModeProvider');
  }
  return context;
};
