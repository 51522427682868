import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate,useParams } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useAuth,getLoggedInUserData } from '../../context/AuthContext';
// Context Files
import { useMenu } from '../../context/MenuContext';

// Css File
import "../../css/sidemenu.css"
import Icons from '../../components/icons'

export default function Sidemenu() {
    // const { dispatch,state } = useAuth();
    const { apiCall } = useApi();
    // const navigate = useNavigate();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);

    const { id } = useParams();
    // --- ---
    const { isActive, toggleMenu, closeMenu } = useMenu();
    const navigate = useNavigate();
    // --- ---
    const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
    const handleLogout = () => {
        dispatch({ type: 'LOGOUT'});
        navigate('/');
    }
    useEffect(() => {
        const fetchData = async () => {
          const loggedInUser = getLoggedInUserData();
          const isUserLoggedIn = !!loggedInUser;
          
          if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
              try {
                // getIbRequestStatus();
                // const affiliateCheck = await checkAffiliateAvailable();
                setDataLoaded(true);
                // console.log(tradeHistory);
                // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
              } catch (error) {
                navigate('/login');
                console.error('Failed to fetch ib data:', error);
              }
            }
          }
        };
  
        if (isInitialMount.current) {
          isInitialMount.current = false;
          fetchData();
        }
    }, [token]);
    // const [isAffiliate, setAffiliate] = useState(0);
    //  const checkAffiliateAvailable = async () => {
    //     try {
    //       // setIsLoading(true);
    //       // console.log({id});
    //       const response = await apiCall(API_URL +'check-affiliate-available', {
    //           user_id: state.userData.id,
    //           token: token
    //       });
    //       if (response.data.success == '0') {
    //         //   console.log(response.data.data);
    //           setAffiliate(response.data.data);
    //         //   setTotalPagesDailySummary(response.data.data.last_page)
    //       }
    //       // setIsLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    //   };
  return (
    <>
        <aside className="side-menu-bx smb-mini">
            <div>
                <NavLink to="/new-challenge" className="smb-link highlight-link">
                    <div className="smb-icon"><Icons.PlusSvg /></div>
                    <div className="smb-tooltop">New Challenge</div>
                </NavLink>
                <div className="smb-link-separator"></div>
                <NavLink to="/home" className="smb-link">
                    <div className="smb-icon"><Icons.HomeSvg /></div>
                    <div className="smb-tooltop">Home</div>
                </NavLink>
                <NavLink to="/payouts" className="smb-link">
                    <div className="smb-icon"><Icons.EwalletsSvg /></div>
                    <div className="smb-tooltop">Payouts</div>
                </NavLink>
                <NavLink to="/leaderboard" className="smb-link">
                    <div className="smb-icon"><Icons.GlobeEuropeAfricaSvg /></div>
                    <div className="smb-tooltop">Leaderboard</div>
                </NavLink>
                <NavLink to="/calendar" className="smb-link">
                    <div className="smb-icon"><Icons.CalendarSvg /></div>
                    <div className="smb-tooltop">Calendar</div>
                </NavLink>
            </div>

            <div>
                <div className="smb-link-separator"></div>
                {/* {isAffiliate == 1 && ( */}

                <NavLink to="/affiliate" className="smb-link">
                    <div className="smb-icon"><Icons.UsersSvg /></div>
                    <div className="smb-tooltop">Affiliate</div>
                </NavLink>
                {/* )} */}
                <NavLink to="/settings" className="smb-link">
                    <div className="smb-icon"><Icons.Cog6Svg /></div>
                    <div className="smb-tooltop">Settings</div>
                </NavLink>
                <Link className="smb-link" onClick={handleLogout}>
                    <div className="smb-icon"><Icons.LogoutSvg /></div>
                    <div className="smb-tooltop">Logout</div>
                </Link>
            </div>

        </aside>

        {/* Aside Full Menu Start */}
        <aside className={`side-menu-bx ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            <NavLink to="/new-challenge" className="smb-link highlight-link">
                <div className="smb-icon"><Icons.PlusSvg /></div>New Challenge
            </NavLink>
            <div className="smb-link-separator"></div>
            <NavLink to="/home" className="smb-link">
                <div className="smb-icon"><Icons.HomeSvg /></div>Home
            </NavLink>
            <NavLink to="/payouts" className="smb-link">
                <div className="smb-icon"><Icons.EwalletsSvg /></div>Payouts
            </NavLink>
            <NavLink to="/leaderboard" className="smb-link">
                <div className="smb-icon"><Icons.GlobeEuropeAfricaSvg /></div>Leaderboard
            </NavLink>
            <NavLink to="/calendar" className="smb-link">
                <div className="smb-icon"><Icons.CalendarSvg /></div>Calendar
            </NavLink>
            <div className="smb-link-separator"></div>
            {/* {isAffiliate == 1 && ( */}
                <NavLink to="/affiliate" className="smb-link">
                    <div className="smb-icon"><Icons.UsersSvg /></div>Affiliate
                </NavLink>
            {/* )} */}
            <NavLink to="/settings" className="smb-link">
                <div className="smb-icon"><Icons.Cog6Svg /></div>Settings
            </NavLink>
            <Link className="smb-link" onClick={handleLogout}>
                <div className="smb-icon"><Icons.LogoutSvg /></div>Logout
            </Link>
        </aside>
        <div onClick={toggleMenu} className={`side-menu-overlay ${isActive ? 'active' : ''}`}></div>
        {/* Aside Full Menu Start */}
    </>
  )
}
